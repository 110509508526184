import React from 'react';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import MainContext from '../context/MainContext';
import PuzzleBanner from './PuzzleBanner';
import AuthContext from '../context/AuthContext';

import atacado_img from './../assets/images/atacado.svg';
import comparar_img from './../assets/images/comparar.svg';
import melhor_preco_img from './../assets/images/melhor-preco.svg';

import banner_main_img from './../assets/images/banner_main.jpg';

function MainBanner() {
  const navigate = useNavigate();
  const {
    loggedInStatus, mainBannerMessageOn, mainBannerMessageOff
  } = useContext(MainContext);

  const { isLogged } = useContext(AuthContext);

  return (
    <article className="w-full rounded-b-md relative">
      {/* DESKTOP */}
      <div 
        className="hidden lg:block w-full bg-gradient-to-r from-cyan-500 to-blue-200 h-[261px] lg:h-[434px]" 
        style={{ 
          backgroundImage: `url(${banner_main_img})`,
          backgroundPosition: 'top left',
          backgroundSize: '60%',
          backgroundRepeat: 'no-repeat',
          backgroundColor: '#93d8f8',
        }}
      >
      </div>

      {/* MOBILE */}
      <div 
        className="lg:hidden w-full bg-gradient-to-r from-cyan-500 to-blue-200 h-[261px] lg:h-[434px]" 
        style={{ 
          backgroundImage: `url(${banner_main_img})`,
          backgroundPosition: 'top 0px right -80px',
          backgroundSize: '85%',
          backgroundRepeat: 'no-repeat',
          backgroundColor: '#93d8f8',
        }}
      >
      </div>

      {
          isLogged ? (
            <>
              {/* DESKTOP */}
              <div className="hidden lg:block absolute top-[100px] right-[330px] w-[150px] md:w-[400px] text-left">
                <h3 className="text-primary-blue md:text-primary-blue text-[17px] lg:text-[21px] pb-[11px] font-medium">
                {mainBannerMessageOn.main}
                </h3>
                <h2 className="text-primary-blue md:text-primary-blue font-bold text-[21px] lg:text-[35px] pb-[45px]">
                  {mainBannerMessageOn.sub}
                </h2>
                <button onClick={() => navigate('/login')} className="hover:shadow-md font-bold w-1/2 bg-primary-blue rounded-xl px-4 py-[12px] text-white hover:bg-tertiary-blue hover:border-tertiary-blue duration-150 mb-[40px] text-[18px]">
                  {mainBannerMessageOn.button}
                </button>
              </div>
              {/* MOBILE */}
              <div className="lg:hidden absolute top-[40px] left-[30px] max-w-[200px] md:w-[400px] text-left">
                <h3 className="text-primary-blue md:text-primary-blue text-[17px] lg:text-[21px] pb-[22px] font-medium">
                {mainBannerMessageOn.main}
                </h3>
                <h2 className="text-primary-blue md:text-primary-blue font-bold text-[21px] lg:text-[35px] pb-[22px]">
                  {mainBannerMessageOn.sub}
                </h2>
                <button onClick={() => navigate('/social-login')} className="hover:shadow-md font-bold w-full bg-primary-blue rounded-xl px-4 py-[12px] text-white hover:bg-tertiary-blue hover:border-tertiary-blue duration-150 mb-[40px] text-[18px]">
                  Cadastre-se
                </button>
              </div>            
            </>            
          ) : (
            <>
              {/* DESKTOP */}
              <div className="hidden lg:block absolute top-[100px] right-[330px] w-[150px] md:w-[400px] text-left">
                <h3 className="text-primary-blue md:text-primary-blue text-[17px] lg:text-[21px] pb-[11px] font-medium">
                {mainBannerMessageOff.main}
                </h3>
                <h2 className="text-primary-blue md:text-primary-blue font-bold text-[21px] lg:text-[35px] pb-[45px]">
                  {mainBannerMessageOff.sub}
                </h2>
                <button onClick={() => navigate('/social-login')} className="hover:shadow-md font-bold w-1/2 bg-primary-blue rounded-xl px-4 py-[12px] text-white hover:bg-tertiary-blue hover:border-tertiary-blue duration-150 mb-[40px] text-[18px]">
                  {mainBannerMessageOff.button}
                </button>
              </div>
              {/* MOBILE */}
              <div className="lg:hidden absolute top-[40px] left-[30px] max-w-[200px] md:w-[400px] text-left">
                <h3 className="text-primary-blue md:text-primary-blue text-[17px] lg:text-[21px] pb-[22px] font-medium">
                {mainBannerMessageOff.main}
                </h3>
                <h2 className="text-primary-blue md:text-primary-blue font-bold text-[21px] lg:text-[35px] pb-[22px]">
                  {mainBannerMessageOff.sub}
                </h2>
                <button onClick={() => navigate('/login')} className="hover:shadow-md font-bold w-full bg-primary-blue rounded-xl px-4 py-[12px] text-white hover:bg-tertiary-blue hover:border-tertiary-blue duration-150 mb-[40px] text-[18px]">
                  Cadastre-se
                </button>
              </div>            
            </>
          )
        }

      {/* DESKTOP */}
      <div className="hidden lg:block bg-primary-blue max-h-[240px]">
        <div className="flex flex-row items-center justify-evenly w-full">
          <div className="items-center py-4 px-20 pl-32">
            <img className='object-cover h-[50px] mx-auto' src={comparar_img} alt="" />
            <p className="text-white font-bold text-center py-[10px] text-[12px] px-[20px]">COMPARADOR<br />DE PREÇO</p>
            <p className="text-gray-300 text-center py-[10px] text-[10px] px-[20px]">Com o nosso sistema, você pode comparar os preços dos concorrentes em cada página de produto e constatar que o menor preço é aqui, no Buquemarque.</p>
          </div>
          <div className="items-center py-4 px-20">
            <img className='object-cover h-[50px] mx-auto' src={melhor_preco_img} alt="" />
            <p className="text-white font-bold text-center py-[10px] text-[12px] px-[20px]">OS MELHORES PREÇOS<br />DA INTERNET</p>
            <p className="text-gray-300 text-center py-[10px] text-[10px] px-[20px]">Nós oferecemos os melhores preços porque eliminamos o intermediário e conectamos diretamente o consumidor final ao atacadista, oferecendo o preço de custo do produto.</p>
          </div>
          <div className="items-center py-4 px-20 pr-32">
            <img className='object-cover h-[50px] mx-auto' src={atacado_img} alt="" />
            <p className="text-white font-bold text-center py-[10px] text-[12px] px-[20px]">NÃO É PROMOÇÃO,<br />É ATACADO</p>
            <p className="text-gray-300 text-center py-[10px] text-[10px] px-[20px]">No Buquemarque, os preços baixos não são resultado de descontos ou promoções, mas sim de compras no atacado realizadas através da união de pessoas.</p>
          </div>
        </div>
      </div>

      {/* MOBILE */}
      <div className="lg:hidden bg-primary-blue max-h-[740px]">
        <div className="flex flex-col items-center w-full">
          <div className="items-center py-4 px-20">
            <img className='object-cover h-[50px] mx-auto' src={comparar_img} alt="" />
            <p className="text-white font-bold text-center py-[10px] text-[12px]">COMPARADOR<br />DE PREÇO</p>
            <p className="text-gray-300 text-center py-[10px] text-[10px]">Com o nosso sistema, você pode comparar os preços dos concorrentes em cada página de produto e constatar que o menor preço é aqui, no Buquemarque.</p>
          </div>
          <div className="items-center py-4 px-20">
            <img className='object-cover h-[50px] mx-auto' src={melhor_preco_img} alt="" />
            <p className="text-white font-bold text-center py-[10px] text-[12px]">OS MELHORES PREÇOS<br />DA INTERNET</p>
            <p className="text-gray-300 text-center py-[10px] text-[10px]">Nós oferecemos os melhores preços porque eliminamos o intermediário e conectamos diretamente o consumidor final ao atacadista, oferecendo o preço de custo do produto.</p>
          </div>
          <div className="items-center py-4 px-20">
            <img className='object-cover h-[50px] mx-auto' src={atacado_img} alt="" />
            <p className="text-white font-bold text-center py-[10px] text-[12px]">NÃO É PROMOÇÃO,<br />É ATACADO</p>
            <p className="text-gray-300 text-center py-[10px] text-[10px]">No Buquemarque, os preços baixos não são resultado de descontos ou promoções, mas sim de compras no atacado realizadas através da união de pessoas.</p>
          </div>
        </div>
      </div>

      {
        isLogged ? (
            <></>
          ) : (
          <PuzzleBanner />
        )
      }
    </article>
  )
}

export default MainBanner;
