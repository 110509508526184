import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
// import WhiteLogo from '../assets/logo/WhiteLogo';
import WhiteLogo from '../assets/logo/logo-branco.png';
import Button from '../components/small_components/Button';
import { AiOutlineMail } from "react-icons/ai";
import './css/SocialLogin.css';

function SocialLogin () {
  const date = new Date();
  const year = date.getFullYear();
  const navigate = useNavigate();

  const svgStyle = {
    width: '20px',
    height: '20px',
    fill: 'white',
    display: 'inline',
    marginRight: '10px'
  };

  return (
    <div className='h-screen bg-background-gray'>
      <header className='w-full bg-primary-red h-[68px]'>
        <Link to="/">
          <img src={WhiteLogo} className='h-16 scale-150 hover:opacity-80 duration-150 mx-auto' />
        </Link>
      </header>
      <section className="bg-background-gray w-full text-center pt-[40px] pb-[10px] xl:pb-[20px]">
        <div className='lg:bg-white max-w-[484px] py-[58px] rounded-xl lg:shadow-md mx-auto h-[502px]'>
          <h2 className='text-primary-blue font-bold text-2xl'>Criar Conta</h2>
          <p className='text-primary-blue px-[58px] pt-[8px] text-sm mb-10'>
            Cadastre-se para ficar por dentro de todas as nossas novidades. É rápido e seguro :)
          </p>
          <div className='flex flex-col items-center justify-center'>
            <div className='my-[8px]'>
              <Button
                onClick={() => navigate('create-account')}
                width='300px'
                text='Criar sua conta com Google'
                color='#EA4335'
                icon={(
                  <svg style={ svgStyle } role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>Gmail</title><path d="M24 5.457v13.909c0 .904-.732 1.636-1.636 1.636h-3.819V11.73L12 16.64l-6.545-4.91v9.273H1.636A1.636 1.636 0 0 1 0 19.366V5.457c0-2.023 2.309-3.178 3.927-1.964L5.455 4.64 12 9.548l6.545-4.91 1.528-1.145C21.69 2.28 24 3.434 24 5.457z"/></svg>
                )}
              />
              
            </div>
            <div className='my-[8px]'>
              <Button
                onClick={() => navigate('create-account')}
                width='300px'
                text='Criar sua conta com Facebook'
                color='#1877F2'
                icon={(
                  <svg style={ svgStyle } role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>Facebook</title><path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z"/></svg>
                )}
              />

            </div>
            <div className='my-[8px]'>
              <Button
                onClick={() => navigate('create-account')}
                width='300px'
                text='Criar sua conta com um email'
                color='#021a75'
                icon={<AiOutlineMail className='mr-2 w-6'/>}
              />

            </div>
          </div>
          <div className='px-[70px] py-[26px]'>
            <hr className='divider1 mt-[20px] mb-[28px]' />
            <p className='text-xs text-primary-blue'>Já tem uma conta? <Link className='hover:underline-offset-auto font-bold text-primary-red hover:opacity-80 underline decoration-solid' to='/login'>Clique aqui</Link> para acessar</p>

          </div>
        </div>
        <div className='h-full flex w-full items-center justify-center'>
          <p className='text-xs text-dark-gray px-14 pt-[40px]'>
            Não se preocupe, nosso site é seguro! Ao criar o seu cadastro, você concorda com a nossa <Link className='font-bold underline decoration-solid' to='/privacypolicies'>Política de Privacidade</Link>
          </p>          
        </div>
      </section>    
      <footer className='bg-background-gray text-[12px] text-center w-full mx-auto py-[15px] text-dark-gray'>
        <hr className='divider2 mb-4 mx-8'/>
        © {year} Mercado Unido. Todos os direitos reservados
      </footer>
    </div>
  )
}

export default SocialLogin;
