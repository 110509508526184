import React, { useContext } from 'react';
import axios from 'axios';
import { IMaskInput } from 'react-imask';
import AuthContext from '../context/AuthContext';
import MainContext from '../context/MainContext';
// import WhiteLogo from '../assets/logo/WhiteLogo';
import WhiteLogo from '../assets/logo/logo-branco.png';
import { Link } from 'react-router-dom';
import { formatDatePtBr } from './../utils/format';
import Footer from '../components/Footer';

import DadosPessoaisFields from '../components/small_components/DadosPessoaisFields';
import EnderecoEntregaFields from '../components/small_components/EnderecoEntregaFields';
import Checkout from './../components/Checkout';
import { useEffect } from 'react';

export default function Payment() {
    const { shoppingCart, setCheckoutItem, idCart } = useContext(AuthContext);
    const { stepper, setStepper, selectedOffer } = useContext(MainContext);

    useEffect(() => {
        setCheckoutItem(shoppingCart.find((item) => item.id === idCart))
    }, []);

    const date = new Date();
    const year = date.getFullYear();

    return (
        <>
            <header className='w-full bg-primary-red h-[68px]'>
                <Link to="/">
                    <img src={WhiteLogo} className='h-16 scale-125 hover:opacity-80 duration-150 mx-auto' />
                </Link>
            </header>
            <section className="bg-background-gray py-10">
                {/* =======@ DESKTOP @======= */}
                <article className='bg-white max-w-[862px] py-[30px] rounded-xl shadow-md mx-auto px-[30px] hidden lg:block'>
                    <ol className="flex items-center w-full text-sm font-medium text-center text-gray-500 dark:text-gray-400 sm:text-base">
                        <li className={(stepper >= 1) ? "text-blue-600 dark:text-blue-500 flex md:w-full items-center sm:after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700" : "flex md:w-full items-center sm:after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700"}>
                            <span className="flex items-center after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-200 dark:after:text-gray-500">
                                {
                                    (stepper >= 1) ? (
                                        <svg className="w-3.5 h-3.5 sm:w-4 sm:h-4 mr-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                        </svg>
                                    ) : (
                                        <span className="mr-2">1</span>
                                    )
                                }
                                Dados <span className="hidden sm:inline-flex sm:ml-2">Pessoais</span>
                            </span>
                        </li>
                        <li className={(stepper >= 2) ? "text-blue-600 dark:text-blue-500 flex md:w-full items-center after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700" : "flex md:w-full items-center after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700"}>
                            <span className="flex items-center after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-200 dark:after:text-gray-500">
                                {
                                    (stepper >= 2) ? (
                                        <svg className="w-3.5 h-3.5 sm:w-4 sm:h-4 mr-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                        </svg>
                                    ) : (
                                        <span className="mr-2">2</span>
                                    )
                                }
                                Endereço <span className="hidden sm:inline-flex sm:ml-2">de</span> <span className="hidden sm:inline-flex sm:ml-2">Entrega</span>
                            </span>
                        </li>
                        <li className={(stepper >= 3) ? 'text-blue-600 dark:text-blue-500 flex items-center' : 'flex items-center'}>
                            {
                                (stepper >= 3) ? (
                                    <svg className="w-3.5 h-3.5 sm:w-4 sm:h-4 mr-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                    </svg>
                                ) : (
                                    <span className="mr-2">3</span>
                                )
                            }
                            Pagamento
                        </li>
                    </ol>
                    {
                        (stepper === 3) ? (
                            <Checkout
                                selectedOffer={selectedOffer}
                                shoppingCart={shoppingCart}
                            />
                        ) : (
                            <form>
                                <div className="mt-[60px] grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                    {
                                        (stepper === 1) ? (
                                            <DadosPessoaisFields />
                                        ) : (stepper === 2) && (
                                            <EnderecoEntregaFields />
                                        )
                                    }

                                    <div className={"flex flex-wrap " + (stepper === 1 ? "justify-end" : "justify-between") + " sm:col-span-6 mt-[20px]"}>
                                        {
                                            (stepper !== 1) && (
                                                <div onClick={() => setStepper(stepper - 1)} className='w-[200px] text-center bg-primary-red font-medium py-[10px] px-[20px] text-white duration-150 rounded-xl hover:cursor-pointer hover:bg-tertiary-red'>
                                                    <div className='flex items-center justify-between'>
                                                        <span>Voltar</span>
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3" />
                                                        </svg>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        <div onClick={() => (stepper !== 3) && setStepper(stepper + 1)} className='w-[200px] text-center bg-primary-blue font-medium py-[10px] px-[20px] text-white duration-150 rounded-xl hover:cursor-pointer hover:bg-tertiary-blue'>
                                            <div className='flex items-center justify-between'>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 15l6-6m0 0l-6-6m6 6H9a6 6 0 000 12h3" />
                                                </svg>
                                                <span>Próximo</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        )
                    }
                </article>

                {/* =======@ MOBILE @======= */}
                <article className='bg-white py-[30px] rounded-xl shadow-md px-[30px] mx-[16px] lg:hidden'>
                    <ol className="flex items-center w-full text-sm font-medium text-center text-gray-500 dark:text-gray-400 sm:text-base">
                        <li className={(stepper >= 1) ? "text-blue-600 dark:text-blue-500 flex md:w-full items-center sm:after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700" : "flex md:w-full items-center sm:after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700"}>
                            <span className="flex items-center after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-200 dark:after:text-gray-500">
                                {
                                    (stepper >= 1) ? (
                                        <svg className="w-3.5 h-3.5 sm:w-4 sm:h-4 mr-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                        </svg>
                                    ) : (
                                        <span className="mr-2">1</span>
                                    )
                                }
                                Dados <span className="hidden sm:inline-flex sm:ml-2">Pessoais</span>
                            </span>
                        </li>
                        <li className={(stepper >= 2) ? "text-blue-600 dark:text-blue-500 flex md:w-full items-center after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700" : "flex md:w-full items-center after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700"}>
                            <span className="flex items-center after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-200 dark:after:text-gray-500">
                                {
                                    (stepper >= 2) ? (
                                        <svg className="w-3.5 h-3.5 sm:w-4 sm:h-4 mr-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                        </svg>
                                    ) : (
                                        <span className="mr-2">2</span>
                                    )
                                }
                                Endereço <span className="hidden sm:inline-flex sm:ml-2">de</span> <span className="hidden sm:inline-flex sm:ml-2">Entrega</span>
                            </span>
                        </li>
                        <li className={(stepper >= 3) ? 'text-blue-600 dark:text-blue-500 flex items-center' : 'flex items-center'}>
                            {
                                (stepper >= 3) ? (
                                    <svg className="w-3.5 h-3.5 sm:w-4 sm:h-4 mr-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                    </svg>
                                ) : (
                                    <span className="mr-2">3</span>
                                )
                            }
                            Pagamento
                        </li>
                    </ol>
                    {
                        (stepper === 3) ? (
                            <Checkout
                                selectedOffer={selectedOffer}
                                shoppingCart={shoppingCart}
                            />
                        ) : (
                            <form>
                                <div className="mt-[20px] grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 pb-8">
                                    {
                                        (stepper === 1) ? (
                                            <DadosPessoaisFields />
                                        ) : (stepper === 2) && (
                                            <EnderecoEntregaFields />
                                        )
                                    }

                                    <div className={"flex flex-wrap " + (stepper === 1 ? "justify-end" : "justify-between") + " sm:col-span-6 mt-[20px]"}>
                                        {
                                            (stepper !== 1) && (
                                                <div onClick={() => setStepper(stepper - 1)} className='w-[200px] text-center bg-primary-red font-medium py-[10px] px-[20px] text-white duration-150 rounded-xl hover:cursor-pointer hover:bg-tertiary-red'>
                                                    <div className='flex items-center justify-between'>
                                                        <span>Voltar</span>
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3" />
                                                        </svg>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        <div onClick={() => (stepper !== 3) && setStepper(stepper + 1)} className='w-[200px] text-center bg-primary-blue font-medium py-[10px] px-[20px] text-white duration-150 rounded-xl hover:cursor-pointer hover:bg-tertiary-blue'>
                                            <div className='flex items-center justify-between'>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 15l6-6m0 0l-6-6m6 6H9a6 6 0 000 12h3" />
                                                </svg>
                                                <span>Próximo</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        )
                    }
                </article>
            </section>
            <footer className='bg-background-gray text-[12px] text-center w-full mx-auto pb-[15px] text-dark-gray'>
                <hr className='divider2 mb-4 mx-8' />
                © {year} Mercado Unido. Todos os direitos reservados
            </footer>
        </>
    )
}