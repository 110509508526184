import React, { useContext } from 'react';
import MainContext from '../../context/MainContext';
import AuthContext from '../../context/AuthContext';
import AddressContext from '../../context/AddressContext';
import Swal from 'sweetalert2';
import { useState } from 'react';
import { Toast } from './../../utils/toast';
import Loader from '../small_components/Loader';

export default function MyAddressModal() {
    const { setIsOpenMyAddressModal, setIsOpenAddressModal } = useContext(MainContext);
    const { user } = useContext(AuthContext);

    const {
        setZipCode, setState,
        setStreet, setComplement,
        setNumber, setReference,
        setDistrict, setIsMainChecked,
        setCity, setIsUpdate, setIdAddress, deleteAddress, setMainAddress
    } = useContext(AddressContext);
    const { token, getUserById } = useContext(AuthContext);

    const [isLoader, setIsLoader] = useState(false);

    const handleClickUpdate = async (e, address) => {
        e.preventDefault();

        setIdAddress(address.id);
        setZipCode(address.cep);
        setState(address.state);
        setStreet(address.street);
        setComplement(address.complement);
        setNumber(address.number);
        setReference(address.reference);
        setDistrict(address.district);
        setIsMainChecked(address.main);
        setCity(address.city);

        setIsOpenMyAddressModal(false);
        setIsOpenAddressModal(true);

        setIsUpdate(true);
    }

    const handleClickDelete = async (e, address) => {
        e.preventDefault();

        try {
            if (address.main) {
                Toast.fire({
                    icon: 'error',
                    title: 'VOCÊ NÃO PODE DELETAR UM ENDEREÇO PRINCIPAL!'
                })

                return;
            }

            setIsLoader(true);

            const response = await deleteAddress(address.id, token);

            if (!response) {
                Toast.fire({
                    icon: 'error',
                    title: 'OCORREU ERRO AO DELETAR UM ENDEREÇO!'
                })

                return
            }

            if (response.status === 200) {
                await getUserById(user.id, token);

                Toast.fire({
                    icon: 'success',
                    title: 'ENDEREÇO DELETADO!'
                })
            }
        } catch {
            Toast.fire({
                icon: 'error',
                title: 'OCORREU ERRO AO DELETAR UM ENDEREÇO!'
            })
        } finally {
            setIsLoader(false);
        }
    }

    const handleClickOpenModalAddAdrress = async (e) => {
        e.preventDefault();

        setIsOpenMyAddressModal(false);
        setIsOpenAddressModal(true);
    }

    const handleClickSetMain = async (e, address) => {
        e.preventDefault();

        try {
            setIsLoader(true);

            const response = await setMainAddress(address.id, token);

            if (!response) {
                Toast.fire({
                    icon: 'error',
                    title: 'OCORREU ERRO AO DEFINIR UM ENDEREÇO COMO PRINCIPAL!'
                })

                return
            }

            if (response.status === 200) {
                await getUserById(user.id, token);

                Toast.fire({
                    icon: 'success',
                    title: 'ENDEREÇO DEFINIDO COMO PRINCIPAL!'
                })
            }
        } catch {
            Toast.fire({
                icon: 'error',
                title: 'OCORREU ERRO AO DEFINIR UM ENDEREÇO COMO PRINCIPAL!'
            })
        } finally {
            setIsLoader(false);
        }
    }

    return (
        <div className="modal h-screen w-full fixed left-0 top-0 flex justify-center items-center bg-black bg-opacity-50">
            <div className="flex flex-col w-11/12 sm:w-5/6 lg:w-1/2 max-w-2xl mx-auto rounded-lg border border-gray-300 shadow-xl">
                <div className="flex flex-row justify-between p-6 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg">
                    <p className="font-semibold text-gray-800">Meus Endereços</p>
                    <span className="cursor-pointer" onClick={() => setIsOpenMyAddressModal(false)}>
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" >
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
                        </svg>
                    </span>
                </div>
                {
                    (isLoader) && (
                        <Loader />
                    )
                }
                <div className="flex flex-col px-6 py-5 bg-gray-50 h-[600px] overflow-auto scrollbar-hide">
                    {
                        <div>
                            <h2>Endereço Principal</h2>
                            <div className="flex justify-between bg-white rounded-md shadow-md p-[16px] my-[12px]">
                                <div className={(user.mainAddress.main) && 'font-bold'}>
                                    <p>{user.mainAddress.street}, {user.mainAddress.number} - {user.mainAddress.district}</p>
                                    <p>{user.mainAddress.state}, {user.mainAddress.city} - {user.mainAddress.cep}</p>
                                    <p>
                                        {(user.mainAddress.complement !== null) && user.mainAddress.complement}
                                        {(user.mainAddress.reference !== null) && ", "}
                                        {(user.mainAddress.reference !== null) && user.mainAddress.reference}
                                    </p>
                                </div>
                                <div className='flex items-center'>
                                    <div className='px-[8px] cursor-pointer' onClick={(e) => {
                                        Swal.fire({
                                            title: 'Você tem Certeza?',
                                            icon: 'warning',
                                            showCancelButton: true,
                                            confirmButtonColor: '#3085d6',
                                            cancelButtonColor: '#d33',
                                            cancelButtonText: 'Cancelar',
                                            confirmButtonText: 'Sim, deletar!'
                                        }).then((result) => {
                                            if (result.isConfirmed) {
                                                handleClickDelete(e, user.mainAddress);
                                            }
                                        })
                                    }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                        </svg>
                                    </div>
                                    <div className='px-[8px] cursor-pointer' onClick={(e) => handleClickUpdate(e, user.mainAddress)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <hr></hr>
                    <div className='pt-[10px]'>
                        <h2>Todos Endereços</h2>
                    </div>
                    {
                        user.adresses.map(address => {
                            return (
                                <div className="flex justify-between bg-white rounded-md shadow-md p-[16px] my-[12px]">
                                    <div className={(address.main) && 'font-bold'}>
                                        <p>{address.street}, {address.number} - {address.district}</p>
                                        <p>{address.state}, {address.city} - {address.cep}</p>
                                        <p>
                                            {(address.complement !== null) && address.complement}
                                            {(address.reference !== null) && ", "}
                                            {(address.reference !== null) && address.reference}
                                        </p>
                                    </div>
                                    <div className='flex items-center'>
                                        <div className='px-[8px] cursor-pointer' onClick={(e) => {
                                            Swal.fire({
                                                title: 'Você tem Certeza?',
                                                icon: 'warning',
                                                showCancelButton: true,
                                                confirmButtonColor: '#3085d6',
                                                cancelButtonColor: '#d33',
                                                cancelButtonText: 'Cancelar',
                                                confirmButtonText: 'Sim, deletar!'
                                            }).then((result) => {
                                                if (result.isConfirmed) {
                                                    handleClickDelete(e, address);
                                                }
                                            })
                                        }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                            </svg>
                                        </div>
                                        <div className='px-[8px] cursor-pointer' onClick={(e) => handleClickUpdate(e, address)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                                            </svg>
                                        </div>
                                        {
                                            (!address.main) && (
                                                <div className='px-[8px] cursor-pointer' onClick={(e) => handleClickSetMain(e, address)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                                    </svg>
                                                </div>
                                            )
                                        }

                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div className="flex flex-row items-center justify-between p-5 bg-white border-t border-gray-200 rounded-bl-lg rounded-br-lg">
                    <p onClick={(e) => handleClickOpenModalAddAdrress(e)} className="cursor-pointer font-semibold text-gray-600">Novo Endereço</p>
                    <button onClick={(e) => setIsOpenMyAddressModal(false)} className="px-4 py-2 text-white font-semibold bg-blue-500 rounded">
                        Fechar
                    </button>
                </div>
            </div>
        </div>
    )
}