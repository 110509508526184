import React, { useContext, useState, useEffect } from 'react';
import MainContext from '../context/MainContext';
import Button from '../components/small_components/Button';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Divider from '../components/small_components/Divider';
import SaleSection from '../components/SaleSection';
import DownSaleTag from '../components/small_components/DownSaleTag';
import Rating from '../components/small_components/Rating';
import RatingSection from '../components/RatingSections';
import QuantitySelector from '../components/small_components/QuantitySelector';
import { BsShareFill } from "react-icons/bs";
import { ImLocation } from "react-icons/im";
import { formatFistPurchaseForTimer, formatMoney, isOfferExpiration, getCurrentDate, addDays, converterData } from '../utils/format';
import './css/ProductPage.css';
import ImageCarousel from '../components/ImagesCarousel';
import { Timer, SmallTimer } from '../components/Timer';
import CompaniesBar from '../components/CompaniesBar';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import LoginModal from '../components/modals/LoginModal';
import Loader from '../components/small_components/Loader'
import { IMaskInput } from 'react-imask';
import axios from 'axios';
import { Toast } from './../utils/toast';

export default function ProductPage() {
  const { isLogged, addItemCart, setIdCart, user, token, getItensCart } = useContext(AuthContext);
  const {
    selectedOffer, deliveryFee,
    counter, setCounter,
    isOpenLogin, setIsOpenLogin,
  } = useContext(MainContext);

  const [zipCode, setZipCode] = useState('');
  const [msgCityState, setMsgCityState] = useState('');

  const handleClickSearchZipCode = async (e) => {
    e.preventDefault();

    if (zipCode === "") {
      Toast.fire({
        icon: 'error',
        title: 'INFORME UM CEP VÁLIDO!'
      })

      return;
    }

    if (zipCode !== "") {
      try {
        axios.get(`https://viacep.com.br/ws/${zipCode.replace(/[.-]/g, "")}/json/`)
          .then((res) => {
            setMsgCityState(`${res.data.localidade} - ${res.data.uf}, ${res.data.bairro}`);

            if (res.data.hasOwnProperty('erro')) {
              Toast.fire({
                icon: 'error',
                title: 'NÃO FOI POSSÍVEL ENCONTRAR SEU CEP!'
              })
            } else {
              Toast.fire({
                icon: 'success',
                title: 'CEP ENCONTRADO!'
              })
            }
          })
      } catch {
        Toast.fire({
          icon: 'error',
          title: 'NÃO FOI POSSÍVEL ENCONTRAR SEU CEP!'
        })
      }

      return;
    }

  }

  const [isLoading, setIsLoading] = useState(false);

  const amount = counter * selectedOffer.price;
  const navigate = useNavigate();

  const addCart = async (e) => {
    e.preventDefault();

    if (isLogged) {
      setIsLoading(true);

      try {
        const response = await addItemCart(counter, amount, selectedOffer.id);

        if (response.status === 201) {
          const responseItensCart = await getItensCart(user.id, token);

          if (responseItensCart.status === 200) {
            setIdCart(response.data.data.id);
            navigate(`/checkout/${selectedOffer.id}`);
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
        setCounter(1);
      }
    } else {
      setIsOpenLogin(true);
    }
  }

  const [totalRate, setTotalRate] = useState(0);

  useEffect(() => {
    const newTotalRate = selectedOffer.rate.reduce((total, review) => {
      return total + parseFloat(review.rate);
    }, 0);

    setTotalRate(parseFloat(newTotalRate) / selectedOffer.rate.length);
  }, [selectedOffer]);

  return (
    <>
      <Header />
      <CompaniesBar />
      <section className="bg-background-gray w-full pt-[85px] lg:pt-[25px]">
        {
          (isLoading) && (
            <Loader />
          )
        }
        {/* MOBILE */}
        <div className='rounded-xl bg-white m-[16px] shadow-md p-[14px] md:hidden'>
          {
            (isOpenLogin) && (
              <LoginModal />
            )
          }
          <div>
            <ImageCarousel />
          </div>
          <p className='text-[18px] font-bold pt-[56px] text-gray-600'>{selectedOffer.title}</p>
          <div className='text-left w-full pt-[7px] pb-[32px] justify-start'>
            <Rating propRating={totalRate.toFixed(1)} />
          </div>
          <section className='flex flex-row justify-start items-center'>
            <DownSaleTag down={selectedOffer.down} />
            <p className="font-bold text-[14px] text-dark-gray line-through mx-2">{formatMoney(selectedOffer.retailPrice)}</p>
          </section>
          <p className="font-bold text-green text-[30px] -mb-2 pt-[11px]">
            {formatMoney(selectedOffer.price)}
          </p>
          <p className="text-dark-gray text-[14px] mb-[40px]">
            em até 12x de {formatMoney(parseInt(selectedOffer.price) / 12)} sem juros no cartão de crédito
          </p>
          <div className='my-[30px]'>
            <QuantitySelector missingUnits={selectedOffer.missingUnits} />
          </div>
          <Button
            text='Compartilhar'
            icon={<BsShareFill className='mr-3' />}
            width='150px'
            fontSize='13px'
          />
          <div className='mt-[20px]'>
            <div
              onClick={(e) =>
                (selectedOffer.firstPurchase === null)
                  ? addCart(e)
                  : (!isOfferExpiration(selectedOffer.firstPurchase, selectedOffer.offerRemainingDays, selectedOffer.offerRemainingTime).hasExpired && selectedOffer.missingUnits !== 0)
                  && addCart(e)
              }
              className={`
              ${(selectedOffer.firstPurchase !== null) &&
                (isOfferExpiration(selectedOffer.firstPurchase, selectedOffer.offerRemainingDays, selectedOffer.offerRemainingTime).hasExpired || selectedOffer.missingUnits === 0) && 'opacity-50'
                } bg-green text-white rounded-xl 
              ${(selectedOffer.firstPurchase === null)
                  ? 'hover:opacity-80 duration-150'
                  : (!isOfferExpiration(selectedOffer.firstPurchase, selectedOffer.offerRemainingDays, selectedOffer.offerRemainingTime).hasExpired && selectedOffer.missingUnits !== 0) && 'hover:opacity-80 duration-150'
                } flex flex-row items-center justify-start font-bold w-[300px] text-center py-[19px] cursor-pointer
            `}
            >
              <p className='text-center w-full text-[16px]'>
                COMPRAR
              </p>
            </div>
          </div>
          <Divider color='#707070' />
          <h2 className='font-bold text-primary-red text-xl'>
            {'Tempo Restante'}
          </h2>
          <Timer
            days={selectedOffer.offerRemainingDays}
            time={selectedOffer.offerRemainingTime}
            firstPurchase={(selectedOffer.firstPurchase === null) ? selectedOffer.firstPurchase : formatFistPurchaseForTimer(selectedOffer.firstPurchase)}
          />
          <Divider color='#707070' />
          <div className='text-center py-[25px]'>
            <p className='text-[22px] font-bold text-yellow-300'>Faltam {selectedOffer.missingUnits}</p>
            <p className='font-bold text-dark-gray py-[4px] text-[16px]'>de {selectedOffer.neededUnities} unidades</p>
            <p className='text-[14px] text-dark-gray'>Para concretizar negócio</p>
          </div>
          <Divider color='#707070' />
          <div className='my-6'>
            <label htmlFor="company-website" className="block text-sm font-medium text-gray-700">
            </label>
            <div className="mt-1 flex rounded-md shadow-sm">
              <IMaskInput
                mask='00.000-000'
                type="text"
                value={zipCode}
                onChange={(e) => {
                  setZipCode(e.target.value.replace(/[^0-9]/g, ''));
                }}
                name="company-website"
                id="company-website"
                className="block w-full min-w-0 flex-1 rounded-none rounded-l-md border-gray-300 px-3 py-2 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                placeholder="Sua localização"
              />
              <button onClick={(e) => handleClickSearchZipCode(e)} className="inline-flex items-center rounded-r-md border border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                <ImLocation color='#021a75' />
              </button>
            </div>
          </div>
          {
            (msgCityState !== '') && (
              <div className='flex flex-row items-center justify-between py-4'>
                <p className='text-green text-[15px]'>Chegará dia <b>{converterData(addDays(getCurrentDate(), 20))} em {msgCityState}</b> após a oferta ser <b>concluida e finalizada.</b></p>
                <p className='font-bold text-dark-gray text-[15px]'>{formatMoney(deliveryFee)}</p>
              </div>
            )
          }
        </div>

        {/* DESKTOP */}
        <div className='hidden rounded-md bg-white shadow-md p-[25px] pb-[45px] md:flex flex-row mx-auto items-between justify-between max-w-[1280px] mx-auto'>
          {
            (isOpenLogin) && (
              <LoginModal />
            )
          }
          <section className='flex flex-col w-[35%]'>
            <ImageCarousel />
          </section>
          <section className='w-[35%] px-4 flex flex-col justify-between border-r-[1.5px] border-gray-100'>
            <p className='text-lg font-bold'>{selectedOffer.title}</p>
            <div className='text-left mt-[16px] mb-[25px]'>
              <Rating propRating={totalRate.toFixed(1)} />
            </div>
            <section className='flex flex-row justify-start items-center'>
              <DownSaleTag down={selectedOffer.down} />
              <p className="font-bold text-sm text-dark-gray line-through mx-2">{formatMoney(selectedOffer.retailPrice)}</p>
            </section>
            <p className="font-bold text-green text-[30px]">
              {formatMoney(selectedOffer.price)}
            </p>
            <span className="text-dark-gray text-xs mt-[2px] mb-[17px]">
              em até 12x de {formatMoney(parseInt(selectedOffer.price) / 12)} sem juros no cartão de crédito
            </span>
            <Button
              text='Compartilhar'
              icon={<BsShareFill className='mr-3' />}
              width='150px'
              fontSize='12px'
            />
            <div className='mt-[40px]'>
              <QuantitySelector missingUnits={selectedOffer.missingUnits} />
            </div>
            <div className='mt-[16px]'>
              <div
                onClick={(e) =>
                  (selectedOffer.firstPurchase === null)
                    ? addCart(e)
                    : (!isOfferExpiration(selectedOffer.firstPurchase, selectedOffer.offerRemainingDays, selectedOffer.offerRemainingTime).hasExpired && selectedOffer.missingUnits !== 0)
                    && addCart(e)
                }
                className={`
                  ${(selectedOffer.firstPurchase !== null) &&
                  (isOfferExpiration(selectedOffer.firstPurchase, selectedOffer.offerRemainingDays, selectedOffer.offerRemainingTime).hasExpired || selectedOffer.missingUnits === 0) && 'opacity-50'
                  } bg-green text-white rounded-xl 
                  ${(selectedOffer.firstPurchase === null)
                    ? 'hover:opacity-80 duration-150'
                    : (!isOfferExpiration(selectedOffer.firstPurchase, selectedOffer.offerRemainingDays, selectedOffer.offerRemainingTime).hasExpired && selectedOffer.missingUnits !== 0) && 'hover:opacity-80 duration-150'
                  } flex flex-row items-center justify-start font-bold w-[300px] text-center py-[19px] cursor-pointer
                `}
              >
                <p className='text-center w-full'>
                  COMPRAR
                </p>
              </div>
            </div>
          </section>
          <section className='w-[30%] pl-[25px]'>
            <h2 className='font-bold text-primary-red text-xl'>
              {'Tempo Restante'}
            </h2>
            <Timer
              days={selectedOffer.offerRemainingDays}
              time={selectedOffer.offerRemainingTime}
              firstPurchase={(selectedOffer.firstPurchase === null) ? selectedOffer.firstPurchase : formatFistPurchaseForTimer(selectedOffer.firstPurchase)}
            />
            <div className='text-center border-y-[1px] border-gray-200 py-[25px]'>
              <p className='text-[22px] font-bold text-yellow-400'>Faltam {selectedOffer.missingUnits}</p>
              <p className='font-bold text-dark-gray text-[16px]'>de {selectedOffer.neededUnities} unidades</p>
              <p className='text-[14px] text-dark-gray'>Para concretizar negócio</p>
            </div>
            <div className='py-4'>
              <label htmlFor="company-website" className="block text-sm font-medium text-gray-700">
              </label>
              <div className="mt-[30px] flex rounded-md shadow-sm">
                <IMaskInput
                  mask='00.000-000'
                  type="text"
                  value={zipCode}
                  onChange={(e) => {
                    setZipCode(e.target.value.replace(/[^0-9]/g, ''));
                  }}
                  name="company-website"
                  id="company-website"
                  className="block w-full min-w-0 flex-1 rounded-none rounded-l-md border-gray-300 px-3 py-2 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  placeholder="Digite seu CEP"
                />
                <button onClick={(e) => handleClickSearchZipCode(e)} className="inline-flex items-center rounded-r-md border border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                  <ImLocation color='#021a75' />
                </button>
              </div>
            </div>
            {
              (msgCityState !== '') && (
                <div className='flex flex-row items-center justify-between py-4'>
                  <p className='text-green text-[15px]'>Chegará dia <b>{converterData(addDays(getCurrentDate(), 20))} em {msgCityState}</b> após a oferta ser <b>concluida e finalizada.</b></p>
                  <p className='font-bold text-dark-gray text-[15px]'>{formatMoney(deliveryFee)}</p>
                </div>
              )
            }
          </section>
        </div>

        <div className='rounded-md bg-white shadow-md px-[34px] py-[49px] my-[10px] max-w-[1280px] mx-[16px] lg:mx-auto py-[28px] lg:py-[30px]'>
          <h2 className='font-bold text-dark-gray text-[21px] pb-[35px]'>Descrição</h2>
          <p className='text-dark-gray text-[15px]'>
            {selectedOffer.description}
          </p>
        </div>
        <div className='rounded-md bg-white m-4 shadow-md p-3 pb-16 max-w-[1280px] lg:mx-auto mx-[16px]'>
          <RatingSection />
        </div>
        <SaleSection category="ending_sales" title="Ofertas mais próximas de expirar" />
      </section>
      <Footer />
    </>
  )
}