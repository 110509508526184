import React, { useState, useEffect } from 'react';
import MainContext from './MainContext';
import * as categorie from "./../services/category";
import * as offer from "./../services/offer";
import * as subCategorie from "./../services/subCategory";
import * as affiliateStores from "./../services/affiliateStore";
import * as affiliateStoresCategory from "./../services/affiliateStoreCategory";
import * as term from './../services/term';

function MainProvider({ children }) {
  const [subCategory, setSubCategory] = useState([]);
  const [category, setCategory] = useState(null);
  const [offers, setOffers] = useState([]);
  const [terms, setTerms] = useState([]);

  useEffect(() => {
    const category = localStorage.getItem('app-mercado-unido-categories');
    const offer = localStorage.getItem('app-mercado-unido-offers');
    const subCategory = localStorage.getItem('app-mercado-unido-sub-category');
    const terms = localStorage.getItem('app-mercado-unido-terms');

    if (category) {
      setCategory(JSON.parse(category));

      return;
    }

    if (offer) {
      setOffers(JSON.parse(offer));

      return;
    }

    if (subCategory) {
      setSubCategory(JSON.parse(subCategory));

      return;
    }

    if (terms) {
      setTerms(JSON.parse(terms));

      return;
    }
  }, [])

  useEffect(() => {
    try {
      getOffers();
      getSubCategorys();
      getTerms();
      getCategorys();
    } catch (error) {
      console.log(error);
    }
  }, [setOffers, setSubCategory]);

  const [menu, setMenu] = useState(false);
  const [profileMenu, setProfileMenu] = useState(false);
  const [loggedInStatus, setLoggedInStatus] = useState(false);
  const [createAccountState, setCreateAccountState] = useState({
    status: false,
    msg: '',
    icon: ''
  });

  const [avatar, setAvatar] = useState(null);

  const [offerSearch, setOfferSearch] = useState('');
  const [subCategorySearch, setSubCategorySearch] = useState({
    idSubCategory: '',
    idCategory: '',
    subCategory: '',
    category: ''
  });

  const [deliveryFee, setDeliveryFee] = useState(19.99);
  const [mainBannerMessageOn, setMainBannerMessageOn] = useState({
    main: 'Seja bem-vindo',
    sub: 'Rede Social de Compra Coletiva',
    button: 'Entrar'
  });

  const [mainBannerMessageOff, setMainBannerMessageOff] = useState({
    main: 'Seja bem-vindo',
    sub: 'Rede Social de Compra Coletiva',
    button: 'Cadastre-se'
  });

  async function getTerms() {
    const response = await term.getAll();

    if (response.status !== 200) return false;

    setTerms(response.data.data);
    localStorage.setItem('app-mercado-unido-terms', JSON.stringify(response.data.data));
  }

  async function getCategorys() {
    const response = await categorie.get();

    if (response.status !== 200) return false;

    setCategory(response.data.data);
    localStorage.setItem('app-mercado-unido-categories', JSON.stringify(response.data.data));
  }

  async function getOffers() {
    const response = await offer.get();

    if (response.status !== 200) return false;

    setOffers(response.data.data);
    localStorage.setItem('app-mercado-unido-offers', JSON.stringify(response.data.data));
  }

  async function getSubCategorys() {
    const response = await subCategorie.get();

    if (response.status !== 200) return false;

    setSubCategory(response.data.data);
    localStorage.setItem('app-mercado-unido-sub-category', JSON.stringify(response.data.data));
  }

  async function insertAffiliateStore(fantasyName, businessName, responsibleName, email, phone, cnpj, website, type, soldAnotherMarketplace, observation) {
    const response = await affiliateStores.insert(
      fantasyName, businessName, responsibleName, 
      email, phone, cnpj, website, type, 
      soldAnotherMarketplace, observation
    );

    if (response.status !== 201) return false;

    return response;
  }

  async function insertAffiliateStoreCategory(id_affiliate_store, id_categorys) {
    const response = await affiliateStoresCategory.insert(id_affiliate_store, id_categorys);

    if (response.status !== 200) return false;

    return response;
  }

  const [selectedOffer, setSelectedOffer] = useState({});
  const [selectedImage, setSelectedImage] = useState({});
  const [selectedCart, setSelectedCart] = useState({});

  const [createAccStep, setCreateAccStep] = useState(1);

  // first, middle, last
  const [finishedOrderStatus, setFinishedOrderStatus] = useState('last');

  const FAQs = [
    {
      id: 1,
      name: 'Qual a diferença do Mercado Unido para outros Marketplaces?',
      answer: 'A principal diferença é o preço, o Mercado Unido liga o consumidor final ao Fabricante/Distribuidor através da união de compradores.',
    },
    {
      id: 2,
      name: 'Se até o final do cronômetro não bater a quantidade necessária de itens vendidos?',
      answer: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur quas nobis rem explicabo reprehenderit facilis assumenda pariatur ullam blanditiis, vero, similique debitis ratione perspiciatis quia adipisci dicta quisquam magni odio.',
    },
    {
      id: 3,
      name: 'Me arrependi da compra. E agora?',
      answer: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur quas nobis rem explicabo reprehenderit facilis assumenda pariatur ullam blanditiis, vero, similique debitis ratione perspiciatis quia adipisci dicta quisquam magni odio.',
    },
    {
      id: 4,
      name: 'Como funciona o processo de compras no Mercado Unido?',
      answer: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur quas nobis rem explicabo reprehenderit facilis assumenda pariatur ullam blanditiis, vero, similique debitis ratione perspiciatis quia adipisci dicta quisquam magni odio.',
    },
    {
      id: 5,
      name: 'Como mudar o meu e-mail de registro?',
      answer: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur quas nobis rem explicabo reprehenderit facilis assumenda pariatur ullam blanditiis, vero, similique debitis ratione perspiciatis quia adipisci dicta quisquam magni odio.',
    },
    {
      id: 6,
      name: 'Como alterar a minha senha?',
      answer: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur quas nobis rem explicabo reprehenderit facilis assumenda pariatur ullam blanditiis, vero, similique debitis ratione perspiciatis quia adipisci dicta quisquam magni odio.',
    },
  ];

  const [finishSignIn, setFinishSignIn] = useState(true);
  const [newAddressModal, setNewAddressModal] = useState(true);

  const [isOpenLogin, setIsOpenLogin] = useState(false);
  const [isOpenAddressModal, setIsOpenAddressModal] = useState(false);
  const [isOpenMyAddressModal, setIsOpenMyAddressModal] = useState(false);

  const [counter, setCounter] = useState(1);
  const [stepper, setStepper] = useState(1);


  const context = {
    FAQs, getOffers,
    menu,
    setMenu,
    profileMenu,
    setProfileMenu,
    loggedInStatus,
    setLoggedInStatus,
    avatar, setAvatar,
    counter, setCounter,
    stepper, setStepper,
    isOpenLogin, setIsOpenLogin,
    isOpenAddressModal, setIsOpenAddressModal,
    isOpenMyAddressModal, setIsOpenMyAddressModal,
    newAddressModal, setNewAddressModal,
    subCategory, setSubCategory,
    offers, setOffers,
    offerSearch, setOfferSearch,
    subCategorySearch, setSubCategorySearch,
    deliveryFee, setDeliveryFee,
    selectedOffer, setSelectedOffer,
    selectedImage, setSelectedImage,
    selectedCart, setSelectedCart,
    createAccStep, setCreateAccStep,
    createAccountState, setCreateAccountState,
    mainBannerMessageOn, setMainBannerMessageOn,
    mainBannerMessageOff, setMainBannerMessageOff,
    finishedOrderStatus, setFinishedOrderStatus,
    getCategorys, category, terms,
    finishSignIn, setFinishSignIn,
    insertAffiliateStore, insertAffiliateStoreCategory,
  };

  return (
    <MainContext.Provider value={context}>
      {children}
    </MainContext.Provider>
  )
}

export default MainProvider;