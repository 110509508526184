import React from 'react';
import { useNavigate } from 'react-router-dom';
import './puzzle.css'

import home_1_img from './../assets/images/home-1.png'
import home_2_img from './../assets/images/home-2.png'
import home_3_img from './../assets/images/home-3.png'
import home_4_img from './../assets/images/home-4.png'
import home_5_img from './../assets/images/home-5.png'
import home_6_img from './../assets/images/home-6.png'

export default function PuzzleBanner () {
  const navigate = useNavigate();
  return (
    <>
      {/* DESKTOP */}
      <div className='bg-white lg:rounded-xl shadow-md mb-[10px] w-full hidden lg:block'>
        <h2 className='text-[32px] text-gray-500 py-[30px] font-bold max-w-[935px] mx-auto pl-[50px]'>Como funciona</h2>
      </div>    
      <section className='flex flex-col justify-center max-w-[935px] mx-auto hidden lg:block'>
        <div className='bg-white rounded-xl shadow-md mb-[10px]  grid grid-cols-2 h-[335px] relative z-[1] py-[60px] px-[90px]'>
          <div className="bg-background-gray absolute -top-[65px] right-[150px] rounded-full w-[110px] h-[110px] -z-[5] shadow-md scale-[1.15] shadow-inner">
          </div>
          <div className="bg-white absolute -top-[65px] right-[150px] rounded-full w-[110px] h-[110px] z-[2]">
          </div>
          <div className="bg-white absolute top-[0px] right-[140px]  rounded-br-full rounded-bl-full w-[130px] h-[60px] z-[2]">
          </div>
          <p className='absolute -top-[72px] right-[185px] text-[72px] text-center text-primary-red font-bold z-[4]'>
            1
          </p>
          <article className='col-span-1'>
            <h3 className='text-[24px] text-primary-blue font-bold'>
              Cadastre-se
            </h3>
            <p className='pt-[24px] pb-[20px] text-[15px]'>
              O cadastro é necessário para fazer parte da rede de compradores e contabilizar como um comprador.
            </p>
            <button
              onClick={() => navigate('/social-login')}
              className="font-bold w-[185px] text-[16px] w-full border border-white bg-primary-blue rounded-xl px-4 py-[14px] text-white hover:bg-tertiary-blue hover:border-tertiary-blue duration-150"
            >
              Cadastre-se
            </button>
          </article>
          <aside className='col-span-1 flex ml-20 justify-end'>
            <img className='object-cover h-[220px] mx-auto' src={home_1_img} alt="" />
          </aside>
        </div>
        <div className='bg-white rounded-xl shadow-md mb-[10px] px-[92px] py-[60px] grid grid-cols-2 h-[335px] relative z-10'>
          <div className="bg-background-gray absolute -top-[65px] left-[150px] rounded-full w-[110px] h-[110px] -z-[5] shadow-md scale-[1.15] shadow-inner">
          </div>
          <div className="bg-white absolute -top-[65px] left-[150px] rounded-full w-[110px] h-[110px] z-[2]">
          </div>
          <div className="bg-white absolute top-[0px] left-[140px]  rounded-br-full rounded-bl-full w-[130px] h-[60px] z-[2]">
          </div>
          <p className='absolute -top-[72px] left-[185px] text-[72px] text-center text-primary-red font-bold z-[4]'>
            2
          </p>
          <aside className='col-span-1 flex mr-20 justify-start items-center'>
            <img className='object-cover h-[190px] mx-auto' src={home_2_img} alt="" />
          </aside>
          <article className='col-span-1 text-right'>
            <h3 className='text-[24px] text-primary-blue font-bold'>
              Escolha o produto
            </h3>
            <p className='pt-[24px] pb-[10px] text-[15px]'>
              Se você for o primeiro a comprar, irá iniciar um cronômetro para que pessoas possam comprar até dar o número necessário para a conclusão do negócio.
            </p>
            <p className='pt-[px] pb-[10px] text-[15px]'>
              Se você não for o primeiro, você irá integrar mais um comprador para aquele produto.
            </p>
          </article>        
        </div>      
        <div className='bg-white rounded-xl shadow-md mb-[10px] px-[92px] py-[60px] grid grid-cols-3 h-[335px] relative z-10'>
          <div className="bg-background-gray absolute -top-[65px] right-[421.5px] rounded-full w-[110px] h-[110px] -z-[5] shadow-md scale-[1.15] shadow-inner">
          </div>
          <div className="bg-white absolute -top-[65px] right-[421.5px] rounded-full w-[110px] h-[110px] z-[2]">
          </div>
          <div className="bg-white absolute top-[0px] right-[411.5px]  rounded-br-full rounded-bl-full w-[130px] h-[60px] z-[2]">
          </div>
          <p className='absolute -top-[72px] right-[455px] text-[72px] text-center text-primary-red font-bold z-[4]'>
            3
          </p>
          <aside className='col-span-1 flex justify-start'>
            <img className='object-cover h-[150px] mx-auto' src={home_3_img} alt="" />
          </aside>
          <article className='col-span-1 text-center'>
            <h3 className='text-[24px] text-primary-blue font-bold'>
              Aguarde
            </h3>
            <p className='pt-[24px] pb-[10px] text-[15px]'>
              Aguarde a quantidade mínima de vendas a ser atingida para que a compra seja concluída (divulgue para seus amigos ou nas redes sociais para atingir mais rapidamente o número necessário para a execução do negócio)
            </p>
          </article>
          <aside className='col-span-1 flex justify-end'>
            <img className='object-cover h-[150px] mx-auto' src={home_4_img} alt="" />
          </aside>        
        </div>
        <div className='grid grid-cols-2 gap-[10px]'>
          <div className='bg-white rounded-xl shadow-md px-[72px] py-[60px] relative z-10 col-span-1'>
            <div className="bg-background-gray absolute -top-[65px] right-[160px] rounded-full w-[110px] h-[110px] -z-[5] shadow-md scale-[1.15] shadow-inner">
            </div>
            <div className="bg-white absolute -top-[65px] right-[160px] rounded-full w-[110px] h-[110px] z-[2]">
            </div>
            <div className="bg-white absolute top-[0px] right-[150px]  rounded-br-full rounded-bl-full w-[130px] h-[60px] z-[2]">
            </div>
            <p className='absolute -top-[72px] right-[195px] text-[72px] text-center text-primary-red font-bold z-[4]'>
              4
            </p>
            <aside className='flex flex-col justify-between h-full items-center'>
              <div className='py-[10px]'>
                <h3 className='text-[24px] px-[40px] text-primary-blue font-bold text-center leading-7'>
                  Quantidade mínima vendida
                </h3>
              </div>

              <div className='py-[10px]'>
                <p className='text-[15px] text-center'>
                  Se bater a quantidade mínima pedida, parabéns! Compra concluída, você economizou muito. Aguarde o seu produto chegar.
                </p>
              </div>
              <div className='py-[10px]'>
                <img className='object-cover h-[150px] mx-auto' src={home_5_img} alt="" />
              </div>
            </aside>
          </div>
          <div className='bg-white rounded-xl shadow-md px-[72px] py-[60px] relative z-10 col-span-1'>
            <div className="bg-background-gray absolute -top-[65px] right-[160px] rounded-full w-[110px] h-[110px] -z-[5] shadow-md scale-[1.15] shadow-inner">
            </div>
            <div className="bg-white absolute -top-[65px] right-[160px] rounded-full w-[110px] h-[110px] z-[2]">
            </div>
            <div className="bg-white absolute top-[0px] right-[150px]  rounded-br-full rounded-bl-full w-[130px] h-[60px] z-[2]">
            </div>
            <p className='absolute -top-[72px] right-[195px] text-[72px] text-center text-primary-red font-bold z-[4]'>
              4
            </p>
            <aside className='flex flex-col justify-between h-full items-center'>
              <div className='py-[10px]'>
                <h3 className='text-[24px] px-[40px] text-primary-blue font-bold text-center leading-7'>
                  Quantidade mínima NÃO vendida
                </h3>
              </div>
              <div className='py-[10px]'>
                <p className='text-[15px] text-center'>
                  Se a quantidade mínima para a conclusão da venda do produto não for atingida até a finalização do TIMER, seu dinheiro é devolvido e o TIMER é zerado. Você pode iniciar novamente a compra.
                </p>
              </div>
              <div className='py-[10px]'>
                <img className='object-cover h-[150px] mx-auto' src={home_6_img} alt="" />
              </div>
            </aside>
          </div>
        </div>
      </section>

      {/* MOBILE */}
      <section className="flex flex-col m-auto p-auto lg:hidden"> 
        <div className='bg-white lg:rounded-xl shadow-md mb-[12px] w-full text-center h-[150px] lg:hidden'>
          <h2 className='text-[32px] text-gray-500 py-[30px] font-bold'>Como funciona</h2>
        </div>
        <div className="flex overflow-x-scroll pb-10 hide-scroll-bar pt-[100px] -top-[102px] relative">
          <div className="flex flex-nowrap ml-[16px]">
            <div className="inline-block pl-1.5">
              <div className="w-[350px] h-[546px] p-[41px] rounded-xl shadow-md bg-white transition-shadow duration-300 ease-in-out relative z-[1]">
                <div className="bg-background-gray absolute -top-[60px] right-[130px] rounded-full w-[90px] h-[90px] -z-[2] shadow-md scale-[1.15] shadow-inner">
                </div>
                <div className="bg-white absolute -top-[60px] right-[130px] rounded-full w-[90px] h-[90px] z-[2]">
                </div>
                <div className="bg-white absolute top-[0px] right-[110px] rounded-br-full rounded-bl-full w-[130px] h-[40px] z-[2]">
                </div>
                <p className='absolute -top-[68px] right-[157px] text-[58px] text-center text-primary-red font-bold z-[4]'>
                  1
                </p>
                <img className='object-cover h-[158px] mx-auto' src={home_1_img} alt="" />
                <h3 className='text-[24px] text-primary-blue font-bold pt-[31px]'>
                  Cadastre-se
                </h3>
                <p className='text-[15px] text-gray-700 mt-[10px] w-[85%]'>
                  O cadastro é necessário para fazer parte da rede de compradores e contabilizar como um comprador.
                </p>
              
                <button onClick={() => navigate('/social-login')} className="font-bold w-[185px] text-[16px] w-full border border-white bg-primary-blue rounded-xl px-4 py-[14px] text-white hover:bg-tertiary-blue hover:border-tertiary-blue duration-150 mt-[67px]"
                >
                  Cadastre-se
                </button>
              </div>
            </div>
            <div className="inline-block px-1.5">
              <div className="w-[350px] h-[546px] p-[41px] rounded-xl shadow-md bg-white transition-shadow duration-300 ease-in-out relative z-[1]">
                <div className="bg-background-gray absolute top-[350px] -left-[60px] rounded-full w-[90px] h-[90px] -z-[2] shadow-md scale-[1.15] shadow-inner-2">
                </div>
                <div className="bg-white absolute top-[350px] -left-[60px] rounded-full w-[90px] h-[90px] z-[2]">
                </div>
                <div className="bg-white absolute top-[330px] -left-[0px] rounded-tr-full rounded-br-full w-[40px] h-[130px] z-[2]">
                </div>
                <p className='absolute top-[347px] -left-[35px] text-[58px] text-center text-primary-red font-bold z-[4]'>
                  2
                </p>
                <img className='object-cover h-[158px] mx-auto' src={home_2_img} alt="" />
                <h3 className='text-[24px] text-primary-blue font-bold pt-[31px]'>
                  Escolha o produto
                </h3>
                <p className='text-[15px] text-gray-700 mt-[10px] w-[85%]'>
                  Se você for o primeiro a comprar, irá iniciar um cronômetro para que pessoas possam comprar até dar o número necessário para a conclusão do negócio.
                  <br /><br />
                  Se você não for o primeiro, você irá integrar mais um comprador para aquele produto.
                </p>
              </div>
            </div>
            <div className="inline-block pr-1.5">
              <div className="w-[358px] h-[546px] p-[41px] rounded-xl shadow-md bg-white transition-shadow duration-300 ease-in-out relative z-[1]">
                <div className="bg-background-gray absolute top-[350px] -left-[60px] rounded-full w-[90px] h-[90px] -z-[2] shadow-md scale-[1.15] shadow-inner-2">
                </div>
                <div className="bg-white absolute top-[350px] -left-[60px] rounded-full w-[90px] h-[90px] z-[2]">
                </div>
                <div className="bg-white absolute top-[330px] -left-[0px] rounded-tr-full rounded-br-full w-[40px] h-[130px] z-[2]">
                </div>
                <p className='absolute top-[347px] -left-[35px] text-[58px] text-center text-primary-red font-bold z-[4]'>
                  3
                </p>
                <img className='object-cover h-[158px] mx-auto' src={home_4_img} alt="" />
                <h3 className='text-[24px] text-primary-blue font-bold pt-[31px]'>
                  Aguarde
                </h3>
                <p className='text-[15px] text-gray-700 mt-[10px] w-[75%]'>
                  Aguarde a quantidade mínima de vendas a ser atingida para que a compra seja concluída (divulgue para seus amigos ou nas redes sociais para atingir mais rapidamente o número necessário para a execução do negócio)
                </p>
              </div>
            </div>            
            <div className="inline-block grid-rows-2 mr-[16px]">
              <div className="w-[700px] row-span-1 h-[267px] rounded-xl shadow-md bg-white transition-shadow duration-300 ease-in-out mb-[10px] px-[45px] flex flex-row items-center relative z-[1]"
              >
                <div className="bg-background-gray absolute top-[120px] -left-[60px] rounded-full w-[90px] h-[90px] -z-[2] shadow-md scale-[1.15] shadow-inner">
                </div>
                <div className="bg-white absolute top-[120px] -left-[60px]  rounded-full w-[90px] h-[90px] z-[2]">
                </div>
                <div className="bg-white absolute top-[100px] left-[0px]  rounded-tr-full rounded-br-full w-[40px] h-[130px] z-[2]">
                </div>
                <p className='absolute top-[115px] -left-[40px] text-[58px] text-center text-primary-red font-bold z-[4]'>
                  4
                </p>
                <article className='w-1/2'>
                  <h3 className='text-[24px] text-primary-blue font-bold'>
                    Quantidade mínima vendida
                  </h3>
                  <p className='text-[15px] text-gray-700 mt-[10px] w-[85%]'>
                    Se bater a quantidade mínima pedida, parabéns! Compra concluída, você economizou muito. Aguarde o seu produto chegar.
                  </p>
                </article>
                <aside className='w-1/2 py-[31px]'>
                  <img className='object-cover h-[160px] mx-auto' src={home_5_img} alt="" />
                </aside>
              </div>
              <div className="w-[700px] row-span-1 h-[267px] rounded-xl shadow-md bg-white transition-shadow duration-300 ease-in-out mb-[10px] px-[45px] flex flex-row items-center relative z-[1]"
              >
                <div className="bg-background-gray absolute top-[120px] -left-[60px] rounded-full w-[90px] h-[90px] -z-[2] shadow-md scale-[1.15] shadow-inner">
                </div>
                <div className="bg-white absolute top-[120px] -left-[60px]  rounded-full w-[90px] h-[90px] z-[2]">
                </div>
                <div className="bg-white absolute top-[100px] left-[0px]  rounded-tr-full rounded-br-full w-[40px] h-[130px] z-[2]">
                </div>
                <p className='absolute top-[115px] -left-[40px] text-[58px] text-center text-primary-red font-bold z-[4]'>
                  4
                </p>
                <article className='w-1/2'>
                  <h3 className='text-[24px] text-primary-blue font-bold'>
                    Quantidade mínima <u>não</u> vendida
                  </h3>
                  <p className='text-[15px] text-gray-700 mt-[10px] w-[85%]'>
                    Se a quantidade mínima para a conclusão da venda do produto não for atingida até a finalização do TIMER, seu dinheiro é devolvido e o TIMER é zerado. Você pode iniciar novamente a compra.
                  </p>
                </article>
                <aside className='w-1/2 py-[31px]'>
                  <img className='object-cover h-[160px] mx-auto' src={home_6_img} alt="" />
                </aside>
              </div>
              
            </div>
          </div>
        </div>
      </section>
    </>
  )
}