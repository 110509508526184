import React, { useState, useEffect } from 'react';
import { getCurrentTimestamp } from './../utils/format';

function Timer(props) {
  var timeSplitArray = props.time.split(':');

  const [remainingTime, setRemainingTime] = useState(null);

  function calculateRemainingTime() {
    const currentTime = new Date().getTime();
    const targetTime = new Date((props.firstPurchase === null) ? '2023-08-17 08:26:45' : props.firstPurchase).getTime() + (props.days * 24 + parseInt(props.time.split(':')[0])) * 60 * 60 * 1000 + parseInt(props.time.split(':')[1]) * 60 * 1000 + parseInt(props.time.split(':')[2]) * 1000;
    return Math.max(targetTime - currentTime, 0);
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTime(calculateRemainingTime());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const days = Math.floor(remainingTime / (24 * 60 * 60 * 1000));
  const hours = Math.floor((remainingTime % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));
  const minutes = Math.floor((remainingTime % (60 * 60 * 1000)) / (60 * 1000));
  const seconds = Math.floor((remainingTime % (60 * 1000)) / 1000);

  return (
    <div className='flex flex-col items-center'>
      <article className='grid grid-cols-4 items-center p-2 rounded-md my-4 w-full'>
        {
          <>
            <div className='col-span-1 flex flex-col items-center justify-center'>
              <h3 className='text-primary-red font-bold border-[5px] border-primary-red rounded-full size-14 content-center text-[22px]'><center>{props.firstPurchase === null ? props.days : days}</center></h3>
              <p className='text-gray-500 font-bold text-[12px]'>Dias</p>
            </div>
            <div className='col-span-1 flex flex-col items-center justify-center'>
              <h3 className='text-primary-red font-bold border-[5px] border-primary-red rounded-full text-[22px] size-14 content-center'><center>{props.firstPurchase === null ? timeSplitArray[0] : hours}</center></h3>
              <p className='text-gray-500 font-bold text-[12px]'>Horas</p>
            </div>
            <div className='col-span-1 flex flex-col items-center justify-center'>
              <h3 className='text-primary-red font-bold border-[5px] border-primary-red rounded-full size-14 content-center text-[22px]'><center>{props.firstPurchase === null ? timeSplitArray[1] : minutes}</center></h3>
              <p className='text-gray-500 font-bold text-[12px]'>Minutos</p>
            </div>
            <div className='col-span-1 flex flex-col items-center justify-center'>
              <h3 className='text-primary-red font-bold border-[5px] border-primary-red rounded-full size-14 content-center mx-auto text-[22px]'><center>{props.firstPurchase === null ? timeSplitArray[2] : seconds}</center></h3>
              <p className='text-gray-500 font-bold text-[12px]'>Segundos</p>
            </div>
          </>
        }
      </article>
    </div >
  )
}

function SmallTimer(props) {
  var timeSplitArray = props.time.split(':');

  const [remainingTime, setRemainingTime] = useState(calculateRemainingTime());

  function calculateRemainingTime() {
    const currentTime = new Date().getTime();
    const targetTime = new Date((props.firstPurchase === null) ? '2023-08-17 08:26:45' : props.firstPurchase).getTime() + (props.days * 24 + parseInt(props.time.split(':')[0])) * 60 * 60 * 1000 + parseInt(props.time.split(':')[1]) * 60 * 1000 + parseInt(props.time.split(':')[2]) * 1000;
    return Math.max(targetTime - currentTime, 0);
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTime(calculateRemainingTime());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const days = Math.floor(remainingTime / (24 * 60 * 60 * 1000));
  const hours = Math.floor((remainingTime % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));
  const minutes = Math.floor((remainingTime % (60 * 60 * 1000)) / (60 * 1000));
  const seconds = Math.floor((remainingTime % (60 * 1000)) / 1000);

  return (
    <div className='flex flex-col items-center'>
      <h2 className='font-bold text-primary-red'>
        Tempo restante
      </h2>
      <article className='grid grid-cols-4 items-center p-1 rounded-md w-full'>
        {
          <>
            <div className='col-span-1 flex flex-col items-center justify-center'>
              <h3 className='text-primary-red font-bold border-4 border-primary-red rounded-full size-11 content-center'>{props.firstPurchase === null ? props.days : days}</h3>
              <p className='text-gray-500 font-bold text-[9px]'>Dias</p>
            </div>
            <div className='col-span-1 flex flex-col items-center justify-center'>
              <h3 className='text-primary-red font-bold border-4 border-primary-red rounded-full size-11 content-center'>{props.firstPurchase === null ? timeSplitArray[0] : hours}</h3>
              <p className='text-gray-500 font-bold text-[9px]'>Horas</p>
            </div>
            <div className='col-span-1 flex flex-col items-center justify-center'>
              <h3 className='text-primary-red font-bold border-4 border-primary-red rounded-full size-11 content-center'>{props.firstPurchase === null ? timeSplitArray[1] : minutes}</h3>
              <p className='text-gray-500 font-bold text-[9px]'>Minutos</p>
            </div>
            <div className='col-span-1 flex flex-col items-center justify-center'>
              <h3 className='text-primary-red font-bold border-4 border-primary-red rounded-full size-11 content-center'>{props.firstPurchase === null ? timeSplitArray[2] : seconds}</h3>
              <p className='text-gray-500 font-bold text-[9px]'>Segundos</p>
            </div>
          </>
        }
      </article>
    </div>
  )
}

export {
  Timer, SmallTimer
}