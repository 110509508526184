import React from "react";
import { useContext } from "react";
import MainContext from "../../context/MainContext";
import { IMaskInput } from 'react-imask';
import { useState } from "react";
import axios from 'axios';

import { Toast } from './../../utils/toast';
import AuthContext from "../../context/AuthContext";
import AddressContext from "../../context/AddressContext";

export default function NewAddressModal() {
    const { setIsOpenAddressModal } = useContext(MainContext);
    const { createAddress, user, token, getUserById } = useContext(AuthContext);

    const { 
        zipCode, setZipCode, state, setState,
        street, setStreet, complement, setComplement,
        number, setNumber, reference, setReference,
        district, setDistrict, isMainChecked, setIsMainChecked,
        city, setCity, isUpdate, idAddress, updateAddress, setIdAddress, setIsUpdate
    } = useContext(AddressContext);

    const [isLoader, setIsLoader] = useState(false);

    const handleClickClearFields = async (e) => {
        e.preventDefault();

        setZipCode('');
        setStreet('');
        setNumber('');
        setDistrict('');
        setCity('');
        setState('');
        setComplement('');
        setReference('');
    }

    const handleClickSearchZipCode = async (e) => {
        e.preventDefault();

        setStreet('');
        setNumber('');
        setDistrict('');
        setCity('');
        setState('');
        setComplement('');
        setReference('');

        if (zipCode === "") {
            Toast.fire({
                icon: 'error',
                title: 'INFORME UM CEP VÁLIDO!'
            })

            return;
        }

        if (zipCode !== "") {
            try {
                axios.get(`https://viacep.com.br/ws/${zipCode.replace(/[.-]/g, "")}/json/`)
                    .then((res) => {
                        setDistrict(res.data.bairro);
                        setCity(res.data.localidade);
                        setStreet(res.data.logradouro);
                        setState(res.data.uf);

                        if (res.data.hasOwnProperty('erro')) {
                            Toast.fire({
                                icon: 'error',
                                title: 'NÃO FOI POSSÍVEL ENCONTRAR SEU CEP!'
                            })
                        } else {
                            Toast.fire({
                                icon: 'success',
                                title: 'CEP ENCONTRADO!'
                            })
                        }
                    })
            } catch {
                Toast.fire({
                    icon: 'error',
                    title: 'NÃO FOI POSSÍVEL ENCONTRAR SEU CEP!'
                })
            }

            return;
        }

    }

    const handleClickUpdateAddress = async (e) => {
        e.preventDefault();

        try {
            if (zipCode !== '' && street !== '' && number !== '' && district !== '' && city !== '' && state !== '') {
                setIsLoader(true);

                const parameters = {
                    street: street,
                    number: number,
                    district: district,
                    city: city,
                    state: state,
                    cep: zipCode.replace(/[^0-9]/g, ''),
                    complement: complement,
                    reference: reference,
                    idAddress: idAddress,
                    main: isMainChecked
                }

                const response = await updateAddress(parameters, token);

                if (!response) {
                    Toast.fire({
                        icon: 'error',
                        title: 'OCORREU ERRO AO ATUALIZAR UM ENDEREÇO!'
                    })

                    return
                }

                if (response.status === 200) {
                    await getUserById(user.id, token);

                    Toast.fire({
                        icon: 'success',
                        title: 'ENDEREÇO ATUALIZADO!'
                    })

                    setIsUpdate(false);

                    setIdAddress('');
                    setZipCode('');
                    setStreet('');
                    setNumber('');
                    setDistrict('');
                    setCity('');
                    setState('');
                    setComplement('');
                    setReference('');
                }

                return;
            }

            Toast.fire({
                icon: 'error',
                title: 'POR FAVOR, PREENCHA OS CAMPOS OBRIGATÓRIOS!'
            })
        } catch {
            Toast.fire({
                icon: 'error',
                title: 'OCORREU UM ERRO AO ATUALIZAR ENDEREÇO!'
            })
        } finally {
            setIsLoader(false);
        }
    }

    const handleClickCreateAddress = async (e) => {
        e.preventDefault();

        try {
            if (zipCode !== '' && street !== '' && number !== '' && district !== '' && city !== '' && state !== '') {
                setIsLoader(true);

                const parameters = {
                    street: street,
                    number: number,
                    district: district,
                    city: city,
                    state: state,
                    cep: zipCode.replace(/[^0-9]/g, ''),
                    complement: complement,
                    reference: reference,
                    userId: user.id,
                    main: isMainChecked
                }

                const response = await createAddress(parameters);

                if (!response) {
                    Toast.fire({
                        icon: 'error',
                        title: 'OCORREU UM ERRO AO ADICIONAR NOVO ENDEREÇO!'
                    })

                    return
                }

                if (response.status === 201) {
                    await getUserById(user.id, token);

                    Toast.fire({
                        icon: 'success',
                        title: 'NOVO ENDEREÇO ADICIONADO!'
                    })

                    setZipCode('');
                    setStreet('');
                    setNumber('');
                    setDistrict('');
                    setCity('');
                    setState('');
                    setComplement('');
                    setReference('');
                }

                return;
            }

            Toast.fire({
                icon: 'error',
                title: 'POR FAVOR, PREENCHA OS CAMPOS OBRIGATÓRIOS!'
            })
        } catch {
            Toast.fire({
                icon: 'error',
                title: 'OCORREU UM ERRO AO ADICIONAR NOVO ENDEREÇO!'
            })
        } finally {
            setIsLoader(false);
        }
    }

    return (
        <div className="modal h-screen w-full fixed left-0 top-0 flex justify-center items-center bg-black bg-opacity-50">
            <div className="flex flex-col w-11/12 sm:w-5/6 lg:w-1/2 max-w-2xl mx-auto rounded-lg border border-gray-300 shadow-xl">
                <div className="flex flex-row justify-between p-6 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg">
                    <p className="font-semibold text-gray-800">{(isUpdate) ? 'Atualizar Endereço' : 'Adicionar Endereço'}</p>
                    <span className="cursor-pointer" onClick={() => setIsOpenAddressModal(false)}>
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" >
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
                        </svg>
                    </span>
                </div>
                <div className="flex flex-col px-6 py-5 bg-gray-50">
                    <div className="mt-[-6px] grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 pb-8">

                        <div className="sm:col-span-4 text-left">
                            <label className="block text-sm font-bold text-[16px] text-primary-blue">
                                CEP *
                            </label>
                            <div className="mt-1 flex rounded-md shadow-sm mb-[20px]">
                                <div className="relative flex flex-grow items-stretch focus-within:z-10">
                                    <IMaskInput
                                        value={zipCode}
                                        mask='00.000-000'
                                        type="text"
                                        onChange={(e) => {
                                            setZipCode(e.target.value.replace(/[^0-9]/g, ''));
                                        }}
                                        className="block w-full rounded-l-[11px] border-gray-300 shadow-sm sm:text-sm py-1 px-2 focus:border-primary-blue focus:outline-none focus:ring-1 focus:ring-primary-blue pr-[11px] pl-[11px] pb-[11px] pt-[11px]"
                                    />
                                </div>
                                <button
                                    onClick={(e) => handleClickSearchZipCode(e)}
                                    type="button"
                                    className="relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-primary-blue bg-primary-blue px-4 py-2 text-sm font-medium text-white hover:opacity-80"
                                >
                                    OK
                                </button>
                            </div>
                            <a target='_blank' href="https://buscacepinter.correios.com.br/app/endereco/index.php" rel="noreferrer" className='text-[16px] text-gray-400 underline '>Não sei meu CEP</a>
                        </div>

                        <div className="sm:col-span-5">
                            <label className="block text-sm font-bold text-[16px] text-primary-blue">
                                Endereço *
                            </label>
                            <div className="mt-1">
                                <input className="block w-full rounded-[11px] border-gray-300 shadow-sm sm:text-sm py-1 px-2 focus:border-primary-blue focus:outline-none focus:ring-1 focus:ring-primary-blue pr-[11px] pl-[11px] pb-[11px] pt-[11px]"
                                    type="text"
                                    value={street}
                                    onChange={(e) => setStreet(e.target.value)}
                                    maxLength="255"
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-1">
                            <label className="block text-sm font-bold text-[16px] text-primary-blue">
                                Número *
                            </label>
                            <div className="mt-1">
                                <input className="block w-full rounded-[11px] border-gray-300 shadow-sm sm:text-sm py-1 px-2 focus:border-primary-blue focus:outline-none focus:ring-1 focus:ring-primary-blue pr-[11px] pl-[11px] pb-[11px] pt-[11px]"
                                    type="text"
                                    value={number}
                                    onChange={(e) => setNumber(e.target.value)}
                                    maxLength="255"
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-3">
                            <label className="block text-sm font-bold text-[16px] text-primary-blue">
                                Bairro *
                            </label>
                            <div className="mt-1">
                                <input className="block w-full rounded-[11px] border-gray-300 shadow-sm sm:text-sm py-1 px-2 focus:border-primary-blue focus:outline-none focus:ring-1 focus:ring-primary-blue pr-[11px] pl-[11px] pb-[11px] pt-[11px]"
                                    type="text"
                                    value={district}
                                    onChange={(e) => setDistrict(e.target.value)}
                                    maxLength="255"
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-2">
                            <label className="block text-sm font-bold text-[16px] text-primary-blue">
                                Cidade *
                            </label>
                            <div className="mt-1">
                                <input className="block w-full rounded-[11px] border-gray-300 shadow-sm sm:text-sm py-1 px-2 focus:border-primary-blue focus:outline-none focus:ring-1 focus:ring-primary-blue pr-[11px] pl-[11px] pb-[11px] pt-[11px]"
                                    type="text"
                                    value={city}
                                    onChange={(e) => setCity(e.target.value)}
                                    maxLength="255"
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-1">
                            <label className="block text-sm font-bold text-[16px] text-primary-blue">
                                Estado *
                            </label>
                            <div className="mt-1">
                                <input className="block w-full rounded-[11px] border-gray-300 shadow-sm sm:text-sm py-1 px-2 focus:border-primary-blue focus:outline-none focus:ring-1 focus:ring-primary-blue pr-[11px] pl-[11px] pb-[11px] pt-[11px]"
                                    type="text"
                                    value={state}
                                    onChange={(e) => setState(e.target.value)}
                                    maxLength="255"
                                />
                            </div>
                        </div>


                        <div className="sm:col-span-2">
                            <label className="block text-sm font-bold text-[16px] text-primary-blue">
                                Complemento
                            </label>
                            <div className="mt-1">
                                <input className="block w-full rounded-[11px] border-gray-300 shadow-sm sm:text-sm py-1 px-2 focus:border-primary-blue focus:outline-none focus:ring-1 focus:ring-primary-blue pr-[11px] pl-[11px] pb-[11px] pt-[11px]"
                                    type="text"
                                    value={complement}
                                    onChange={(e) => setComplement(e.target.value)}
                                    maxLength="255"
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-3">
                            <label className="block text-sm font-bold text-[16px] text-primary-blue">
                                Ponto de referência
                            </label>
                            <div className="mt-1">
                                <input className="block w-full rounded-[11px] border-gray-300 shadow-sm sm:text-sm py-1 px-2 focus:border-primary-blue focus:outline-none focus:ring-1 focus:ring-primary-blue pr-[11px] pl-[11px] pb-[11px] pt-[11px]"
                                    type="text"
                                    value={reference}
                                    onChange={(e) => setReference(e.target.value)}
                                    maxLength="255"
                                />
                            </div>
                        </div>

                    </div>

                    <div className="flex items-center mt-5 space-x-4">
                        <input
                            className="inline-flex"
                            type="checkbox"
                            id="check2"
                            name="check2"
                            checked={isMainChecked}
                            onChange={() => setIsMainChecked(!isMainChecked)}
                        />
                        <label className="inline-flex font-semibold text-blue-500" for="check2">
                            <span>
                                Definir como endereço <b>principal</b>
                            </span>
                        </label>
                    </div>
                </div>
                <div className="flex flex-row items-center justify-between p-5 bg-white border-t border-gray-200 rounded-bl-lg rounded-br-lg">
                    <p onClick={(e) => handleClickClearFields(e)} className="cursor-pointer font-semibold text-gray-600">Limpar</p>
                    <button onClick={(e) => (isUpdate) ? handleClickUpdateAddress(e) : handleClickCreateAddress(e)} className="px-4 py-2 text-white font-semibold bg-blue-500 rounded">
                        {
                            (isLoader) && (
                                <svg aria-hidden="true" role="status" className="inline mr-2 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2"></path>
                                </svg>
                            )
                        }
                        {(isUpdate) ? 'Atualizar' : 'Adicionar'}
                    </button>
                </div>
            </div>
        </div>
    )
}