import axios from 'axios';
import { url } from './api';
import { addBusinessDays } from './../utils/format';

export const generateQrCodePix = async (
    token, description, value, id_product, id_cart, id_offer
) => {
    try {
        const response = await axios.post(url + '/charge/pix', {
            description: description,
            value: value,
            id_product: id_product,
            id_cart: id_cart,
            id_offer: id_offer,
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        return response;
    } catch (error) {
        console.log(error);

        return false;
    }
}

export const generateBoleto = async (
    token, description, value, 
    street_shipping, number_shipping, locality_shipping, city_shipping, region_code_shipping, postal_code_shipping, 
    id_product, id_cart, id_offer
) => {
    try {
        const response = await axios.post(url + '/charge/boleto', {
            description: description,
            value: value,
            street_shipping: street_shipping,
            number_shipping: number_shipping,
            locality_shipping: locality_shipping,
            city_shipping: city_shipping,
            region_code_shipping: region_code_shipping,
            postal_code_shipping: postal_code_shipping,
            id_product: id_product,
            id_cart: id_cart,
            id_offer: id_offer,
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        return response;
    } catch (error) {
        console.log(error);

        return false;
    }
}

export const payWithCreditCard = async (token, value, installments, card, id_cart, id_offer, id_product) => {
    try {
        const response = await axios.post(url + '/charge/credit/card', {
            description: 'Compra no Buquemarque.',
            value: value,
            installments: installments,
            card: card,
            id_cart: id_cart,
            id_offer: id_offer,
            id_product: id_product
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        return response;
    } catch (error) {
        console.log(error);

        return false;
    }
}